import React from "react";
import "./Footer.css";

function getYear() {
  return new Date().getFullYear();
}

function Footer() {
  return (
    <>
      <div className="footer-container">
        <div className="footer-link">
          <h3 className="website-rights">©{getYear()} NVP Software LLC</h3>
        </div>
      </div>
    </>
  );
}

export default Footer;
