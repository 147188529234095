import React from "react";
import "../App.css";
import "./HeroSection.css";

function HeroSection() {
  return (
    <div className="banner-wrap">
      <div className="wsite-elements wsite-not-footer wsite-header-elements">
        <div className="wsite-section-wrap">
          <div
            className="wsite-section wsite-header-section wsite-section-bg-image"
            style={{
              height: "400px",
              verticalAlign: "middle",
              backgroundImage: 'url("/images/Graph-1.jpg")',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "50% 84%",
              backgroundSize: "cover",
              backgroundColor: "transparent",
            }}
          >
            <div className="banner_text">
              <p>Experts in application development, strategy & design</p>
              <p>Supporting research on COVID-19</p>
            </div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroSection;
