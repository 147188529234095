import React, { useEffect } from "react";

import "./Services.css";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="service-container">
        <div className="service-preview">
          <h1>DIGITAL SERVICES</h1>
        </div>
        <div className="service-wrapper">
          <div className="service-box">
            <div className="box-header">
              <h2>Cloud Services</h2>
            </div>
            <p>
              We are helping large, global enterprises become digital by using
              cloud as part of a foundation to derive new insights, unlock new
              scaleability opportunities and build new models for creating
              richer, more meaningful customer experiences. We offer solutions
              in the areas of Cloud Assessment, Development & Deployment of
              Custom Apps on Cloud, Cloud Migration Services, Cloud & On Premise
              Integration and Cloud Management & Support Services.​
            </p>
          </div>
          <div className="service-box">
            <div className="box-header">
              <h2>Mobility</h2>
            </div>
            <p>
              Since the advent of the mobile revolution, the key factor for
              business success has steeply shifted from business processes to
              user experience. Providing a seamless and unified experience to
              users across Android or iOS devices is of prime importance. This
              means you will now have to use a wide range of platforms to ensure
              that your apps are meeting the UX expectations of your customers.
              To cater to such diverse and complex requirements, we offer mobile
              application development services for hybrid, native web / mobile
              applications– from concept, ideation, design, and development to
              testing.
            </p>
          </div>
          <div className="service-box">
            <div className="box-header">
              <h2>Testing Services</h2>
            </div>
            <p>
              We understand the importance of software Quality and hence offer
              Testing Services under the bouquet 'ESP Testing' which stands for
              Earlier, Smarter, Parallel Testing - a totally innovative and
              holistic approach to testing. This approach to testing ensures an
              optimal environment for our clients to address all the major
              testing challenges and achieve enhanced results.
            </p>
          </div>
          <div className="service-box">
            <div className="box-header">
              <h2>Big Data & Analytics Services</h2>
            </div>
            <p>
              Data from devices, social media, sensors & things, as well as
              real-time interaction data from online channels, when combined
              with existing enterprise data assets, can create a deluge of
              information. Big Data is changing the way organizations get and
              use this information for better decision making an operations. Our
              expertise and experience in Big Data spans across open source
              Hadoop frameworks, NoSQL, Hortonworks and Oracle Big Data. We
              offer analytics services to extract real value from your data and
              to help you make decisions in real-time and redefine your
              business. In the analytics space, we offer comprehensive expert
              services using Hadoop, R, Python, and C# / .Net and Watson
              Analytics
            </p>
          </div>
          <div className="service-box">
            <div className="box-header">
              <h2>UX/UI</h2>
            </div>
            <p>
              Our team of UX and UI designers and developers combine user
              psychology, design experience and technology expertise to create
              engaging and consistent digital experiences across every
              touchpoint providing our customers new opportunities for
              accelerated growth. We offer UI / UX consulting, refresh, design
              and development services in the areas of Web Applications, Mobile
              Applications, E-commerce Portals, enterprise-scale software
              applications. Our design is based strongly on research and
              understanding and are intuitive, easy to use and natural, and
              completely aligned with your goals. UX Design and Development
              Process : Requirements, Architecture, Wireframe Design, Visual
              Design, Develop and Deliver.
            </p>
          </div>
          <div className="service-box">
            <div className="box-header">
              <h2>IoT Services</h2>
            </div>
            <p>
              Our IoT strategy consulting offers a full suite of services, with
              platforms including Microsoft IOT Services, Amazon IOT Services,
              IFTTT, Samsung's SmartThings platform, IBM Watson IOT Services,
              iOS HomeKit platform, and Google's Nest platform.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
