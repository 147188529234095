import React, { useEffect } from "react";

import "./Team.css";

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="team-container">
        <div className="team-preview">
          <h1>Our Leaders</h1>
        </div>
        <div className="team-wrapper">
          <div className="team-box">
            <img alt="img" src="images/team/ip-img_orig.png" />
            <h2>Igor Pyanin</h2>
            <h2>Co-Founder</h2>
            <h2>​Engagement Lead</h2>
          </div>
          <div className="team-box">
            <img alt="img" src="images/team/aa-img_1.png" />
            <h2>Anton Antonov</h2>
            <h2>Co-Founder</h2>
            <h2>​VP, Engineering</h2>
          </div>
          <div className="team-box">
            <img alt="img" src="images/team/yk_orig.png" />
            <h2>Yuliya Kindziayeva</h2>
            <h2>Co-Founder</h2>
            <h2>​EPM</h2>
          </div>
          <div className="team-box">
            <img alt="img" src="images/team/vm_img.png" />
            <h2>Viktor Miedviediev</h2>
            <h2>Director, Engineering</h2>
          </div>
          <div className="team-box">
            <img alt="img" src="images/team/ni-img_orig.png" />
            <h2>Nikolai Ivanov</h2>
            <h2>Director, QA</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default Team;
