import React from "react";
import "./ShowDemo.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import 'photoswipe/dist/photoswipe.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

function ShowDemo() {
  
  return (
    <>
      <Container>
        <div className="show-container">
          <Row>
            <div className="show-preview">
              <p>
                Whether augmenting with your existing team or taking full
                responsibility for product delivery, we treat every project like
                our own.​ We apply the latest development methodologies and
                technologies, along with industry best practices, to deliver
                compelling world-class mobile and web based applications, on
                time and on-budget.
              </p>
            </div>
          </Row>
          
          <Gallery>
            
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/emerge-1024x585.jpg"
                    width="1024"
                    height="585"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/emerge-1024x585.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/nook-main-1.jpg"
                    width="980"
                    height="620"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/nook-main-1.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item original="images/goog.jpg" width="980" height="420">
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/goog.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item original="images/juul-i.jpg" width="800" height="400">
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/juul-i.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item original="images/6dbytes.jpg" width="800" height="533">
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/6dbytes.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/savant-1-1024x576.jpg"
                    width="1024"
                    height="576"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/savant-1-1024x576.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item original="images/astr2.png" width="712" height="400">
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/astr2.png" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/emerge.jpeg"
                    width="1678"
                    height="1082"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/emerge.jpeg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item original="images/biobot.png" width="1500" height="941">
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/biobot.png" />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>
          </Gallery>
        </div>
      </Container>
    </>
  );
}

export default ShowDemo;
