import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import "./Prototypes.css";

import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";

const Prototypes = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        <div className="prototypes-container">
          <div className="prototypes-preview">
            <h1>Charging Parking Spaces</h1>
            <p>
              Provide a solution to charging electric vehicles at work and home,
              without a need to lay the wires or perform other electrical work
              and be able to adjust the number of charging stations quickly.
            </p>
          </div>
          <Gallery>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/ev-charger-final-134-1_orig-1024x576.jpg"
                    width="1024"
                    height="576"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/ev-charger-final-134-1_orig-1024x576.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/img-1061_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/img-1061_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/img-1070_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/img-1070_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/75439218-2571894193043908-6804826577533140992-n_orig-1024x722.jpg"
                    width="1024"
                    height="722"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/75439218-2571894193043908-6804826577533140992-n_orig-1024x722.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/73349296-1367381203425982-781677407852560384-n_orig-1024x722.jpg"
                    width="1024"
                    height="722"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/73349296-1367381203425982-781677407852560384-n_orig-1024x722.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/73209067-1240828176124664-1377190617901170688-n_orig-1-1024x722.jpg"
                    width="1024"
                    height="722"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/73209067-1240828176124664-1377190617901170688-n_orig-1-1024x722.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/74448237-663509387507879-6630098937040601088-n_orig-1024x722.jpg"
                    width="1024"
                    height="722"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/74448237-663509387507879-6630098937040601088-n_orig-1024x722.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/73008848-444242682883184-5858818996471595008-n_orig-1024x722.jpg"
                    width="1024"
                    height="722"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/73008848-444242682883184-5858818996471595008-n_orig-1024x722.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/75339699-415737489327782-5529610513442406400-n_orig-1024x722.jpg"
                    width="1024"
                    height="722"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/75339699-415737489327782-5529610513442406400-n_orig-1024x722.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/img-0765_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/img-0765_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/img-0770_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/img-0770_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/prototypes/img-0768_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/prototypes/img-0768_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>
          </Gallery>
        </div>
      </Container>
    </>
  );
};

export default Prototypes;
