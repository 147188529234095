import React, { useEffect } from "react";
import HeroSection from "../components/HeroSection";
import ShowDemo from "../components/ShowDemo";

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeroSection />
      <ShowDemo />
    </>
  );
};

export default Home;
