import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import "./Contact.css";

import "photoswipe/dist/photoswipe.css";

import { Gallery, Item } from "react-photoswipe-gallery";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container>
        <div className="contact-container">
          <div className="contact-wrapper">
            <div className="contact-box">
              <h1>Talk To Us</h1>
              <p>​sales@nvpsoftware.com</p>
              <p>​(650) 918-0801</p>
            </div>
            <div className="contact-box">
              <h1>USA Headquarters</h1>
              <p>​2111 Coronet Blvd,</p>
              <p>Belmont, CA 94002</p>
            </div>
            <div className="contact-box">
              <h1>Armenian Office</h1>
              <p>​1 Italy st, Yerevan, Armenia</p>
            </div>
            <div className="contact-box">
              <h1>Ukraine / ​R&D Office</h1>
              <p>​84 Oleksandrivska St. Suite 501</p>
              <p>​Zaporizhzhia, Ukraine​ 69000</p>
            </div>
          </div>
          <div className="contact-space"></div>
          <Gallery>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-2119_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/img-2119_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-2115_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/img-2115_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-4550_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/img-4550_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-4438_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/img-4438_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/juul2_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/juul2_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/juul1_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/juul1_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/google1_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/google1_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/google2_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image
                        ref={ref as React.MutableRefObject<HTMLImageElement>}
                        onClick={open}
                        src="images/contact/google2_1_orig-1024x768.jpg"
                      />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}></Col>
            </Row>
          </Gallery>
        </div>
      </Container>
    </>
  );
};

export default Contact;

/*06122022
import React from 'react';
import '../../App.css';
import './Contact.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';

import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'
import { Gallery, Item } from 'react-photoswipe-gallery'

export default function Contact() {
  return (
    <>
      <Container>
        <div className="contact-container">
          <div className="contact-wrapper">
            <div className="contact-box">
              <h1>Talk To Us</h1>
              <p>​sales@nvpsoftware.com</p>
              <p>​(650) 918-0801</p>
            </div>
            <div className="contact-box">
              <h1>USA Headquarters</h1>
              <p>​2111 Coronet Blvd,</p>
              <p>Belmont, CA 94002</p>
            </div>
            <div className="contact-box">
              <h1>Armenian Office</h1>
              <p>​1 Italy st, Yerevan, Armenia</p>
            </div>
            <div className="contact-box">
              <h1>Ukraine / ​R&D Office</h1>
              <p>​84 Oleksandrivska St. Suite 501</p>
              <p>​Zaporizhzhia, Ukraine​ 69000</p>
            </div>
          </div>
          <div class='contact-space'></div>
          <Gallery>
            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-2119_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/img-2119_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-2115_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/img-2115_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-4550_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/img-4550_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/img-4438_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/img-4438_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/juul2_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/juul2_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/juul1_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/juul1_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/google1_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/google1_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
                <div className="show-box">
                  <Item
                    original="images/contact/google2_1_orig-1024x768.jpg"
                    width="1024"
                    height="768"
                  >
                    {({ ref, open }) => (
                      <Image ref={ref as React.MutableRefObject<HTMLImageElement>} onClick={open} src="images/contact/google2_1_orig-1024x768.jpg" />
                    )}
                  </Item>
                </div>
              </Col>
              <Col xs={12} sm={12} md={4}>
              </Col>
            </Row>
          </Gallery>
        </div>
      </Container>
    </>
  );
}
*/
