import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

//PAGES
import Home from "./pages/Home";
import Services from "./pages/Services";
import Clients from "./pages/Clients";
import Team from "./pages/Team";
import Contact from "./pages/Contact";
import Prototypes from "./pages/Prototypes";
import Careers from "./pages/Careers";

const App = () => {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/services" element={<Services />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/team" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/prototypes" element={<Prototypes />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
};

export default App;
