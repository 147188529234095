import React, { useEffect } from "react";

import "./Clients.css";

const Clients = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="clients-container">
        <div className="clients-preview">
          <h1>TESTIMONIALS</h1>
          <p>
            “NVP Software was pivotal to support Emerge’s software platform
            development needs, specifically, helping to develop core parts of
            the Cloud / Mobile experience for Emerge TV leveraging core aspects
            of the AWS platform. Additionally, NVP Software helped migrate our
            CI/CD pipeline from Azure to AWS, helping to reduce cost and also
            ensure compatibility with regards to Security, Governance and scale
            / performance”,
            <strong> Isaac Castro</strong>, Co-Founder & CTO at Emerge
          </p>
        </div>
        <div className="clients-preview">
          <h1>OUR CLIENTS​</h1>
          <p>
            ​Founded in 2017 and headquartered in the San Francisco Bay Area, we
            deliver custom solutions for companies of all sizes, across a wide
            range of industries. With expertise across all major mobile
            platforms, NVP Software is the trusted partner of some of the
            world’s most innovative companies, including Apple, Google and JUUL.
          </p>
        </div>
        <div className="clients-space"></div>
        <div className="clients-wrapper">
          {
            <div className="clients-box">
              <a href="https://www.apple.com/" target="_blank" rel="noreferrer">
                <img alt="img" src="images/clients/image.png" />
              </a>
            </div>
          }
          <div className="clients-box">
            <a
              href="https://www.onepeloton.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="img" src="images/clients/peloton-logo-orig.png" />
            </a>
          </div>
          <div className="clients-box">
            <a href="https://www.biobot.io/" target="_blank" rel="noreferrer">
              <img alt="img" src="images/clients/biobot-logo-black.png" />
            </a>
          </div>
          <div className="clients-box">
            <a
              href="https://www.astrazeneca.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img alt="img" src="images/clients/astra2.png" />
            </a>
          </div>
          <div className="clients-box">
            <a href="https://atap.google.com/" target="_blank" rel="noreferrer">
              <img alt="img" src="images/clients/googleatap-logo-orig.png" />
            </a>
          </div>
          <div className="clients-box">
            <a href="https://www.juul.com/" target="_blank" rel="noreferrer">
              <img alt="img" src="images/clients/juul.png" />
            </a>
          </div>
          <div className="clients-box">
            <a href="https://emerge.io/" target="_blank" rel="noreferrer">
              <img alt="img" src="images/clients/emergenow.png" />
            </a>
          </div>
          <div className="clients-box">
            <a
              href="https://www.barnesandnoble.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="img"
                src="images/clients/1200px-b-n-nook-logo-svg-orig.png"
              />
            </a>
          </div>
          <div className="clients-box">
            <a href="https://www.savant.com/" target="_blank" rel="noreferrer">
              <img alt="img" src="images/clients/savant-1-orig.png" />
            </a>
          </div>
          <div className="clients-box">
            <a href="https://www.blendid.com/" target="_blank" rel="noreferrer">
              <img alt="img" src="images/clients/untitled-orig.png" />
            </a>
          </div>
          <div className="clients-box">
            <a href="https://www.skyflow.com/" target="_blank" rel="noreferrer">
              <img
                alt="img"
                src="images/clients/60538d2d05ab7749afff8c49_logo_skyflow_color.svg"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
