import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";

function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <div className="navbar-container">
          <NavLink to="/" className="navbar-logo" onClick={closeMobileMenu}>
            <img src="/images/logo-white.png" alt="NVP Software" />
          </NavLink>
          <div className="menu-icon" onClick={handleClick}>
            <i className={click ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={click ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " nav-selected" : "")
                }
                onClick={closeMobileMenu}
                end={true}
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/services"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " nav-selected" : "")
                }
                onClick={closeMobileMenu}
              >
                Services
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/clients"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " nav-selected" : "")
                }
                onClick={closeMobileMenu}
              >
                Clients
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/team"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " nav-selected" : "")
                }
                onClick={closeMobileMenu}
              >
                Team
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " nav-selected" : "")
                }
                onClick={closeMobileMenu}
              >
                Contact
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/careers"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " nav-selected" : "")
                }
                onClick={closeMobileMenu}
              >
                Careers
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink
                to="/prototypes"
                className={({ isActive }) =>
                  "nav-links" + (isActive ? " nav-selected" : "")
                }
                onClick={closeMobileMenu}
              >
                Prototypes
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
