import { useCallback, useEffect } from "react";

import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import "./Careers.css";

interface Props {
  link: string;
}

const RedirectButton: React.FC<Props> = ({ link }) => {
  const handleRedirect = useCallback(() => {
    window.open(link, "_blank");
  }, [link]);

  return (
    <div className="row justify-content-end">
      <div className="col-md-3">
        <div className="d-grid gap-2">
          <Button onClick={handleRedirect} variant="primary" size="lg">
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
};

const Careers = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Container fluid>
        <div className="careers-container">
          <Accordion alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Change Management Lead</Accordion.Header>
              <Accordion.Body>
                <h2>Job Summary</h2>
                <p>
                  ​Seeking an experienced Change Management and Training lead to
                  design, develop and deliver trainings, and perform required
                  change management activities to ensure the successful adoption
                  of new and existing tools developed by the team.
                </p>
                <p>
                  This position will require the ability to collaborate across a
                  large number of functions, meet tight deadlines, strong
                  writing and communication skills, good personal organization,
                  and an ability to connect with business partners and
                  stakeholders from around the world.
                </p>
                <h2>Description</h2>
                <ul>
                  <li>
                    Act as a Change Management Lead on project teams to develop,
                    create, and maintain a change Management strategy that
                    includes Stakeholder Analysis, Change Impact Analysis,
                    Communication, Training and Documentation , Adoption
                    Monitoring and handoff Plans
                  </li>
                  <li>
                    Identify communication channels and create written
                    communications to project stakeholders, end users, and other
                    project participants
                  </li>
                  <li>
                    Interview Key Project Stakeholders and Project Management
                    teams to determine impact, receptivity to change, Identify
                    risks and develop risk mitigation tactics. Help Product
                    Manager define adoption metrics and measurements pre and
                    post launch.
                  </li>
                  <li>
                    Work closely with Product and Project Management team to
                    integrate the Change Management plan into the overall
                    project plan and provide related status updates to Biz Ops
                    Solutions project teams and leadership.
                  </li>
                  <li>
                    Participate as needed in requirements gathering sessions,
                    design playbacks, systems playbacks, UATs to better
                    understand the solutions being delivered, create training
                    materials that are aligned with strategic business
                    objectives, and communicate the impact and benefits the new
                    process/tool has on business operations.{" "}
                  </li>
                  <li>
                    Create all training materials (Videos, FAQs, Demos,
                    Keynotes, User Guides, etc), Training delivery (1:1,
                    classroom, and virtual) managing all training logistics,
                    including scheduling, requesting and tracking Super User /
                    Project Team participation & execution, and participate in
                    Lessons Learned.
                  </li>
                </ul>
                <h2>Key Qualifications</h2>
                <ul>
                  <li>Professional Program/Project management experience.</li>
                  <li>
                    Adoption, Change Management, and Training experience within
                    a highly dynamic corporate environment. Experience with
                    instructional design, adult learning, curriculum
                    curation/development, and/or classroom
                    delivery/facilitation. Formal education in Adult Learning
                    would be a plus.
                  </li>
                  <li>
                    Excellent verbal and written communication skills and
                    attention to detail.
                  </li>
                  <li>
                    Proven ability to partner with key/global stakeholders in a
                    matrixed environment and build strong relationships
                  </li>
                  <li>
                    Ability to adapt under pressure, with positive and
                    professional “customer-focused” attitude.
                  </li>
                  <li>Effective facilitation and influencing skills.</li>
                  <li>
                    Great organizational, project, and time management skills.
                  </li>
                  <li>Team player &amp; able to collaborate with others</li>
                </ul>
                <h2>Education & Experience</h2>
                <ul>
                  <li>
                    3 to 5 years experience with medium to high complexity
                    change management projects
                  </li>
                  <li>
                    Bachelor’s Degree in Business administration, Adult
                    Learning, Education, Instructional Design, Organizational
                    Leadership, Human Resources or Communication preferred
                  </li>
                  <li>Change management certification would be a plus</li>
                </ul>
                <RedirectButton
                  link={
                    "https://www.indeed.com/job/change-management-lead-94968e2b54aac2da"
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Product Manager</Accordion.Header>
              <Accordion.Body>
                <h2>Job Summary</h2>
                <p>
                  Seeking an experienced Product Manager to lead complex, global
                  initiatives through process improvement, cross-functional
                  alignment, solution design & implementation. Applying a blend
                  of technical expertise, business analysis, and design
                  thinking, you will interact directly with cross-functional
                  teams across Product, Business Development, Operations,
                  Marketing, Supply Chain, Engineering & Analytics to define,
                  build and roll-out scalable and user-friendly solutions.
                </p>
                <p>
                  This position offers the opportunity to optimize operations,
                  drive efficiency and digital transformation through process
                  improvement, solution implementation & streamlining system
                  integration. If you are passionate about delivering quality
                  solutions through innovative process & tool design, we hope
                  you will consider joining us.
                </p>
                <h2>Description</h2>
                <ul>
                  <li>
                    Partner with business stakeholders and engineering teams to
                    define solutions, roadmap, requirements, deliverables,
                    acceptance criteria, and desired end result
                  </li>
                  <li>
                    Provide recommendations that address gaps, measuring the
                    impact of initiatives, and communicating updates to key
                    stakeholders to drive adoption
                  </li>
                  <li>
                    Evaluate global business processes and design creative,
                    user-friendly and scalable solutions, considering options of
                    building, buying, and leveraging technology
                  </li>
                  <li>
                    Using deep understanding of process optimization, provide
                    analysis and visibility into recurring business issues and
                    inefficiencies, to identify strategies to help reduce time
                    and cost
                  </li>
                </ul>
                <h2>Key Qualifications</h2>
                <ul>
                  <li>
                    5+ years experience as a Product Manager in process
                    management, optimization, and tool implementation
                  </li>
                  <li>
                    Proven history of managing and shipping successful software
                    products with cross-functional teams at scale including rest
                    of the world
                  </li>
                  <li>
                    Strong developer product instincts; intuitively understand
                    why product ideas will be effective and gain adoption
                  </li>
                  <li>
                    Fundamentally analytical and technical; you have the ability
                    to collect and analyze data from a variety of sources,
                    identify key insights, spot optimization opportunities, and
                    inform product decisions
                  </li>
                  <li>
                    Knowledge of project management best practices, including
                    change management, solution roll out and training
                  </li>
                  <li>
                    Familiarity with user-centric design, customer journey
                    mapping, persona development, business process
                    mapping/workflow analysis
                  </li>
                </ul>
                <h2>Education & Experience</h2>
                <ul>
                  <li>BS/BA degree or equivalent experience</li>
                  <li>5+ years of product management experience</li>
                </ul>
                <RedirectButton
                  link={
                    "https://www.indeed.com/job/product-manager-2c22eaafbc44498d"
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Product Test Manager</Accordion.Header>
              <Accordion.Body>
                <h2>Job Description</h2>
                <p>
                  The Process & Tools Team is currently seeking a Product Test
                  Manager. The Product Test Manager is responsible for
                  facilitating and leading user acceptance testing of the
                  features and functionalities of the product developed by the
                  Development Team and ensuring that the scope meets the test
                  requirements. The Test Manager monitors the test process and
                  determine the accurate strategies to establish efficient
                  methods for the success of test deliveries and the
                  communication of it.
                </p>

                <h2>Responsibilities</h2>
                <ul>
                  <li>
                    Depending on the project assignment, responsibilities may
                    include some or all, but are not limited to coordinate UAT
                    testers by communicating across multiple lines of business,
                    organizing meetings and supporting testing efforts&nbsp;
                  </li>
                  <li>
                    <span></span>
                    Create/write test cases and test plans using existing
                    templates&nbsp;
                  </li>
                  <li>
                    Identify test cases that can be used on future
                    initiatives&nbsp;
                  </li>
                  <li>Maintain accurate test cases as systems advance&nbsp;</li>
                  <li>Participate in project meetings as needed</li>
                  <li>
                    Work with Project Team to ensure project success&nbsp;
                  </li>
                  <li>
                    Train SMEs and testers on the execution of test cases and
                    reporting defects&nbsp;
                  </li>
                  <li>
                    Document defects found during testing and monitor defects
                    until issues are resolved&nbsp;
                  </li>
                  <li>
                    Communicate and assist in triage with the Product Mangers,
                    Development and QE Teams on defects found during testing
                  </li>
                  <li>
                    Communicate testing status on a regular basis and escalate
                    critical issues as required&nbsp;
                  </li>
                  <li>Perform all other duties as requested or assigned</li>
                </ul>
                <h2>Qualifications</h2>
                <ul>
                  <li>
                    Knowledge of User Acceptance Testing (UAT) best practices
                  </li>
                  <li>Strong organizational and coordination skills</li>
                  <li>Ability to work in a fast-paced environment</li>
                  <li>Ability to create UAT test plans, cases and scripts</li>
                  <li>Problem solving abilities</li>
                  <li>Analytics background is nice to have</li>
                  <li>
                    Knowledge of test planning, test case development,
                    execution, and reporting
                  </li>
                  <li>Attention to detail</li>
                  <li>
                    Flexibility to adapt to change and willing to learn and
                    develop new skill sets as applicable
                  </li>
                  <li>
                    Excellent written and verbal communication skills&nbsp;
                  </li>
                  <li>Previous experience working at Apple is preferred</li>
                  <li>Previous Quality Assurance experience is preferred</li>
                </ul>
                <h2>Technical Skills</h2>
                <ul>
                  <li>Test case/script writing </li>
                </ul>
                <RedirectButton
                  link={
                    "https://www.indeed.com/job/product-test-manager-a4874dbb2f4f027e"
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Product Manager</Accordion.Header>
              <Accordion.Body>
                <h2>Job Description</h2>
                <p>
                  The Process & Tools Team is currently seeking a Product
                  Manager that will be responsible for gathering and
                  prioritizing customer requirements to create a product. Their
                  primary responsibility lies in defining a clear functional
                  vision that aligns with Business Operations goals while
                  keeping up-to-date on all developments essential to achieving
                  the desired outcomes.
                </p>

                <h2>Responsibilities</h2>
                <ul>
                  <li>
                    Elicit business requirements from the Product Design Team
                  </li>
                  <li>
                    Define and document requirements and then analyze/prioritize
                    accordingly to populate the backlog
                  </li>
                  <li>
                    Write and refine user stories, lead refinement sessions with
                    the Product Design Team and Scrum Team, and make refinements
                    to user stories as needed&nbsp;
                  </li>
                  <li>
                    Create low or high-fidelity prototypes accordingly (e.g.,
                    wireframes)&nbsp;
                  </li>
                  <li>
                    Lead requirements elicitation and documentation with
                    increased complexity&nbsp;
                  </li>
                  <li>
                    Responsible for eliciting requirements / user stories and
                    representing them to the Development Team throughout the
                    systems development cycle&nbsp;
                  </li>
                  <li>
                    Development and on-going maintenance of business process
                    models, business requirements/user stories (functional and
                    non-functional) and product backlogs&nbsp;
                  </li>
                  <li>
                    Serves as the requirements liaison between the Business Ops
                    and the IT Teams
                  </li>
                  <li>
                    Independently facilitate sessions with end users, asking
                    probing questions to elicit business process discussion and
                    capture process flow diagrams&nbsp;
                  </li>
                  <li>Performs other related duties as assigned&nbsp;</li>
                </ul>
                <h2>Qualifications</h2>
                <ul>
                  <li>
                    Comprehensive understanding of requirement/business analyst
                    fundamentals&nbsp;
                  </li>
                  <li>
                    Thorough understanding of writing &amp; leading Requirements
                    sessions&nbsp;
                  </li>
                  <li>
                    In-depth knowledge and practice of Agile Methodologies and
                    concepts, such as Iterations, Epics, Features &amp; User
                    Stories&nbsp;
                  </li>
                  <li>
                    High level understanding of Business Process Modeling&nbsp;
                  </li>
                  <li>
                    Thorough knowledge of understanding of business issues and
                    data challenges&nbsp;
                  </li>
                  <li>Thorough understanding of writing user stories&nbsp;</li>
                  <li>Experience in leading and managing scrum calls&nbsp;</li>
                  <li>
                    Knowledge of prioritizing backlog user stories and
                    prioritization&nbsp;
                  </li>
                </ul>
                <RedirectButton
                  link={
                    "https://www.indeed.com/job/product-manager-59bdb2054ca1efec"
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Product non Technical Manager</Accordion.Header>
              <Accordion.Body>
                <h2>Job Description</h2>
                <p>
                  The Process & Tools Team is currently seeking a non-Technical
                  Release Planning Manager to stay on top of the release
                  management lifecycle including scheduling and coordinating
                  releases across different priorities. Focus on coordinating
                  different aspects of production, coordinating with different
                  stakeholders and acting as a stitch in communicating the
                  release plan and calendar of necessary components. The Release
                  Manager will care about ensuring that timelines, resources,
                  and process quality are all considered and accounted for and
                  be able to communicate them to the stakeholders and partners.
                </p>
                <h2>Responsibilities</h2>
                <ul>
                  <li>Planning release windows and cycles&nbsp;</li>
                  <li>
                    Constructing a release calendar for the different projects
                    to have a centralized view of all releases
                  </li>
                  <li>
                    Managing relationships working on interrelated processes
                  </li>
                  <li>Communicating crucial release plans and changes&nbsp;</li>
                  <li>
                    Measuring and monitoring progress to achieve a timely
                    release within defined quality standards&nbsp;
                  </li>
                  <li>
                    Coordinating processes between different teams (possibly in
                    various locations)&nbsp;
                  </li>
                  <li>
                    Communicating necessary release details to
                    business&nbsp;teams
                  </li>
                  <li>
                    Facilitate planning and negotiating of release activities
                  </li>
                  <li>
                    Initiating and overseeing progressive release processes
                  </li>
                  <li>
                    Leading and coordinating checklist and deployment plan
                    execution&nbsp;
                  </li>
                  <li>
                    Ensuring that releases operate as per expectations&nbsp;
                  </li>
                  <li>
                    Ensuring that requirements are clear across dependent
                    project streams with an effective release
                  </li>
                  <li>
                    Creating, communicating release notes to the stakeholders
                    and partner teams
                  </li>
                </ul>
                <h2>Qualifications</h2>
                <ul>
                  <li>
                    A two to six-year experience in using project or release
                    management&nbsp;tools
                  </li>
                  <li>
                    An expert understanding of software development lifecycle
                  </li>
                  <li>Excellent written and verbal communication skills</li>
                  <li>Excellent analytical&nbsp;skills</li>
                  <li>Structured thinking</li>
                  <li>
                    Experience in coordinating cross-functional work teams up to
                    task completion
                  </li>
                  <li>
                    Technical or non&nbsp;Technical Release
                    Management&nbsp;experience is a plus
                  </li>
                </ul>
                <RedirectButton
                  link={
                    "https://www.indeed.com/job/product-non-technical-manager-1b5b750f8e145c6c"
                  }
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </Container>
    </>
  );
};
export default Careers;
/*
<Accordion.Item eventKey="WRITE NUMBER">
  <Accordion.Header>Product Manager</Accordion.Header>
    <Accordion.Body>
      <h2>Job Summary</h2>
      <p></p>
      <h2>Description</h2>
      <ul>
        <li></li>
      </ul>
      <h2>Key Qualifications</h2>
      <ul>
        <li></li>
      </ul>
      <h2>Education & Experience</h2>
      <ul>
        <li></li>
      </ul>
      <RedirectButton
        link={
          "https://"
        }
      />
    </Accordion.Body>
</Accordion.Item>
 */
